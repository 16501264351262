<template>
  <div class="authorizations-create-page">
    <AuthorizationBreadcrumb :active-step="1"/>
    <div class="page-content">
      <div class="container">
        <div v-if="error"
             class="row">
          <div class="col-md-12">
            <FormError :error="error"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <FormCityAutocomplete v-model="form.city_start"
                                  id="startCity"
                                  name="startCity"
                                  type="text"
                                  label="Ville de départ"
                                  placeholder="Saisir et sélectionner une ville"/>
          </div>
          <div class="col-md-6">
            <FormCityAutocomplete v-model="form.city_end"
                                  id="endCity"
                                  name="endCity"
                                  type="text"
                                  label="Ville d'arrivée"
                                  placeholder="Saisir et sélectionner une ville"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <FormInput v-model="form.num_authorization_on_pdf"
                       type="text"
                       id="num_authorization_on_pdf"
                       name="num_authorization_on_pdf"
                       label="Numéro de l'autorisation"
                       placeholder="Numéro"/>
          </div>
          <div class="col-md-4">
            <FormInput v-model="form.num"
                       type="text"
                       id="num"
                       name="num"
                       label="Numéro interne"
                       placeholder="Numéro"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <FormSelect v-model="form.tonnage"
                        id="tonnage"
                        name="tonnage"
                        label="Tonnage"
                        placeholder="Sélectionner"
                        :options="tonnages"
                        value-to-select="tonnage"
                        label-field="tonnage"/>
          </div>
          <!-- <div class="col-md-3">
            <FormInput v-model="form.weight"
                       type="number"
                       id="weight"
                       name="weight"
                       label="Poids (en kg)"
                       placeholder="kg"/>
          </div> -->
          <div class="col-md-6">
            <FormInput v-model="form.dimensions"
                       type="text"
                       id="dimensions"
                       name="dimensions"
                       label="Longueur x largeur x hauteur (en m)"
                       placeholder="00,00x00,00x00,00"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <FormDatePicker v-model="form.date_start"
                            id="date_start"
                            name="date_start"
                            label="Date de début de validité"
                            placeholder="JJ/MM/AAAA"/>
          </div>
          <div class="col-md-4">
            <FormDatePicker v-model="form.date_end"
                            id="date_end"
                            name="date_end"
                            label="Date de fin de validité"
                            placeholder="JJ/MM/AAAA"/>
          </div>
          <div class="col-md-4">
            <FormFileInput v-model="form.pdf"
                           id="pdf"
                           name="pdf"
                           label="Document de l'autorisation"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <FormTextArea v-model="form.comment"
                          id="comment"
                          name="comment"
                          label="Commentaire"
                          placeholder="Texte de commentaire"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button @click="cancel"
                    class="btn btn-outline-secondary mr-2">
              Annuler
            </button>
            <button @click="submit"
                    :disabled="!formFilled"
                    class="btn btn-secondary">
              Continuer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import FormCityAutocomplete from '@/components/FormCityAutocomplete'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormDatePicker from '@/components/FormDatePicker'
import FormTextArea from '@/components/FormTextArea'
import FormFileInput from '@/components/FormFileInput'
import FormError from '@/components/FormError'
import { createAuthorization, listCategories } from '@/api/premat'
import { parseDate, parseDimensions, parseWeight } from '@/utils'

export default {
  name: 'AuthorizationsCreatePage',
  components: {
    AuthorizationBreadcrumb,
    FormCityAutocomplete,
    FormInput,
    FormSelect,
    FormDatePicker,
    FormTextArea,
    FormFileInput,
    FormError
  },
  data () {
    return {
      form: {
        city_start: null,
        city_end: null,
        categorie: null,
        num_authorization_on_pdf: null,
        date_start: null,
        date_end: null,
        tonnage: null,
        weight: 0,
        dimensions: null,
        pdf: null,
        comment: null,
        num: null
      },
      tonnages: [],
      error: null
    }
  },
  computed: {
    formFilled () {
      return !!this.form.city_start
        && !!this.form.city_end
        && !!this.form.num_authorization_on_pdf
        && !!this.form.date_start
        && !!this.form.date_end
        && !!this.form.tonnage
        // && !!this.form.weight
        && !!this.form.dimensions
        && !!this.form.pdf
        && !!this.form.num
    }
  },
  methods: {
    getTonnages () {
      listCategories()
        .then(response => {
          this.tonnages = response.data.categories
        })
        .catch(() => {
          this.error = {
            message: 'Impossible de récupérer la liste des tonnages, veuillez contacter l\'administrateur du site'
          }
        })
    },
    cancel () {
      this.$router.push({ name: 'authorizations.index' })
    },
    submit () {
      if (!this.formFilled) return
      this.error = null
      createAuthorization(this.getFormData())
        .then(response => {
          this.$router.push({
            name: 'authorizations.steps.create',
            params: { id: response.data.authorization.id }
          })
        })
        .catch(error => {
          if (error.response) {
            this.error = error.response.data
          } else {
            this.error = {
              message: 'Une erreur est survenue, merci de réessayer...'
            }
          }
        })
    },
    getFormData () {
      const formData = new FormData()
      formData.append('num', this.form.num)
      formData.append('city_start', this.form.city_start)
      formData.append('city_end', this.form.city_end)
      formData.append('num_authorization_on_pdf', this.form.num_authorization_on_pdf)
      formData.append('tonnage', this.form.tonnage)
      // const weight = parseWeight(this.form.weight)
      // formData.append('tonne', weight.tonne)
      // formData.append('kilogram', weight.kilogram)
      formData.append('tonne', 0)
      formData.append('kilogram', 0)
      const dimensions = parseDimensions(this.form.dimensions)
      formData.append('length', dimensions.length)
      formData.append('width', dimensions.width)
      formData.append('height', dimensions.height)
      formData.append('date_start', parseDate(this.form.date_start, 'Date de début de validité'))
      formData.append('date_end', parseDate(this.form.date_end, 'Date de fin de validité'))
      formData.append('pdf', this.form.pdf)
      formData.append('comment', this.form.comment)
      return formData
    }
  },
  mounted () {
    this.getTonnages()
  }
}
</script>

<style language="scss">
  .authorizations-create-page {
    flex-direction: column;
  }

  .container-fluid {
     padding-top: 2em;
  }
</style>
